import React from 'react'

export default class Admin extends React.Component{

    render(){
        return(
            <div>
                Admin
            </div>
        )
    }
    
}